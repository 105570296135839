import React from "react";
import styled from "@emotion/styled";
import { AnimatePresence } from "framer-motion";

export const LangChangeButton = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <a href="https://itips.tech/">
        <Button>EN</Button>
      </a>
    </AnimatePresence>
  );
};

const Button = styled.button`
  width: 8vw;
  max-width: 225px;
  height: 5.972vw;
  max-height: 86px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "TT Norms Pro";
  font-weight: 700;
  font-size: min(24px, 1.667vw);
  line-height: 150%;
  color: #ffffff;
  position: relative;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }
`;
