import React from "react";
import styled from "@emotion/styled";


interface Props {
  title: string;
}

const TitleWithBorderWhite = (props: Props) => {
  const { title } = props;
  return (
    <Wrapper>
      <h1>
        {title}
      </h1>
      <div />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: min(0.417vw, 6px);
  justify-content: center;
  align-items: center;

  h1 {
    font-size: min(3.472vw, 50px);
    /* font-family: "Noto Sans", sans-serif;
    font-family: "Noto Sans JP", sans-serif; */
    font-family: 'TT Commons';
    font-weight: 700;
    line-height: 160%;
    text-align: center;
    letter-spacing: 0.03em;
    color: #f2f2f2;
  }

  div {
    width: min(5.417vw, 78px);
    height: min(0.486vw, 7px);
    background-color: #ffffff;
    opacity: 0.5;
  }
`;

export default TitleWithBorderWhite;