import React, { FC } from "react";
import styled from "@emotion/styled";

import FlexColumn from "../../../layout/FlexColumn";
import FlexRowCenter from "../../../layout/FlexRowCenter";
import Spacer from "../../../layout/Spacer";

interface Props {
  title: string;
  paragraph: string;
  image?: React.ReactNode;
  locationURL: string;
  id: string;
}

const Location: FC<Props> = (props) => {
  const { title, paragraph, image, locationURL, id } = props;

  return (
    <Wrapper id={id}>
      <FlexColumn>
        {/* <Margin marginLeft="10.5vw" marginRight="8vw"> */}
        <FlexRowCenter className="location-overview" gap="min(207px, 14.375vw)">
          <FlexColumn className="location-text" gap="min(2.778vw, 40px)">
            <p className="location-title">{title}</p>
            <p className="location-paragraph">{paragraph}</p>
          </FlexColumn>
          {image}
        </FlexRowCenter>
        {/* </Margin> */}
        <Spacer height="min(4vw,57px)" />
        <div className="map">
          <iframe
            loading="lazy"
            src={locationURL}
            width="100%"
            height="250px"
          ></iframe>
        </div>
      </FlexColumn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .i4ewOd-pzNkMb-haAclf {
    display: none;
  }
  .i4ewOd-pzNkMb-nK2kYb {
    display: none;
  }
  iframe {
    width: 100%;
    max-height: 510px;

    filter: grayscale(1);
  }
  .map {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
  }

  .location-overview {
    align-items: flex-start;
    width: 80%;
    margin: 0 auto;
  }

  .location-text {
    width: 45vw;
    max-width: 490px;

    .location-title {
      font-family: "Noto Sans", sans-serif;
      font-family: "Noto Sans JP", sans-serif;
      font-size: min(35px, 2.431vw);
      font-weight: bold;
      color: #1e1e1e;
    }

    .location-paragraph {
      font-family: "Noto Sans", sans-serif;
      font-family: "Noto Sans JP", sans-serif;
      font-size: min(18px, 1.25vw);
      color: #4f4f4f;
      line-height: 2;
      white-space: pre-wrap;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .location-overview {
      flex-direction: column;
      align-items: center;
    }

    .location-text {
      order: 1;
      padding-bottom: 10vw;
    }

    iframe {
      min-height: 200px;
    }
  }
`;
export default Location;
