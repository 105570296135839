import React from "react";
import styled from "@emotion/styled";


interface Props {
    title: string;
}

const TitleWithBorderBlack = (props: Props) => {
    const { title } = props;
    return (
        <Wrapper>
            <h1>
                {title}
            </h1>
            <div />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: min(0.208vw, 3px);
    justify-content: center;
    align-items: center;

    h1 {
        /* font-family: "Noto Sans", sans-serif;
        font-family: "Noto Sans JP", sans-serif; */
        font-size: min(36px, 2.5vw);
        font-family: 'Yu Gothic Pr6N';
        font-weight: 700;
        line-height: 160%;
        text-align: center;
        letter-spacing: 0.03em;
        color: #4f4f4f;
    }

    div {
        width: min(16.458vw, 237px);
        height: min(0.625vw, 9px);
        background-color: #d9d9d9;
    }
`;

export default TitleWithBorderBlack;