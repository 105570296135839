import React from "react";
import styled from "@emotion/styled";
import { motion, Variants } from "framer-motion";

interface Props {
  children: React.ReactNode;
  StaticImage: React.ReactNode;
  size: string;
}

const circleVariants: Variants = {
  offscreen: {
    y: 100,
    scale: 0.8,
    opacity: 0,
  },
  onscreen: {
    y: 0,
    scale: 1,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
      // delay: 0.5,
    }
  }
};

const CircleCaption = (props: Props) => {
  return (
    <Wrapper
      whileTap={{ scale: 1.2 }}
      variants={circleVariants}
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0 }}
    >
      <div
        className="circle-caption"
        style={{ width: props.size, height: props.size }}
      >
        {props.StaticImage}

        <div className="caption">{props.children}</div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  .circle-caption {
    position: relative;
  }

  .circle-bg {
    width: min(427px, 29.653vw);
    height: min(427px, 29.653vw);
    border-radius: 50%;
    opacity: 0.65;
  }

  .caption {
    width: 80%;
    text-align: center;
    font-family: "Noto Sans", sans-serif;
    font-family: "Noto Sans JP", sans-serif;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default CircleCaption;
