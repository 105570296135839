import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { AnimatePresence } from "framer-motion";

const DetailButton = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Link to="/service">
        <Button>
          <span>More Detail</span>
        </Button>
      </Link>
    </AnimatePresence>
  );
};

const Button = styled.button`
  width: min(325px, 22.569vw);
  min-width: 100px;
  height: min(103px, 7.153vw);
  min-height: 30px;
  background-color: #ffffff;
  border-radius: min(70px, 4.861vw);
  font-family: "Noto Sans", sans-serif;
  font-weight: bold;
  font-size: min(32px, 2.222vw);
  text-align: center;

  position: relative;
  overflow: hidden;
  transition: ease 0.2;

  span {
    position: relative;
    z-index: 3;
    color: #204e73;
    transition: color 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: #eb5757;
    box-shadow: 0 0 0 2px #eb5757;
    border-radius: 70px;
    width: 100%;
    height: 100%;
    transition: transform 0.6s cubic-bezier(0.8, 0, 0.2, 1) 0s;
    transform: scale(0, 1);
    transform-origin: right center;
  }

  &:hover {
    span {
      color: #ffffff;
    }

    &::before {
      transform-origin: left center;
      transform: scale(1, 1);
    }
  }
`;

export default DetailButton;
