import * as React from "react";
import styled from "@emotion/styled";
import Helmet from "react-helmet";

import Header from "../components/common/Header";
import Footer from "../components/common/Footer";
import BusinessPurpose from "../components/pages/home/BusinessPurpose";
import News from "../components/pages/home/News/News";
import Mission from "../components/pages/home/Mission";
import Vision from "../components/pages/home/Vision";
import About from "../components/pages/home/About/About";
import Company from "../components/pages/home/Company/Company";
import Contact from "./contact";


const IndexPage = () => {
  return (
    <Wrapper>
      <Helmet>
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;600;700&display=swap');
          @import
          url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&family=Noto+Sans:wght@400;600;700&display=swap');
        </style>
        <script src="https://maps.google.com/maps/api/js?sensor=true"></script>
      </Helmet>

      <Header />

      <BusinessPurpose />

      <News />

      <Mission />

      <About />

      <Company />

      <Contact />

      <Footer />
    </Wrapper>
    
  );
};

const Wrapper = styled.div`
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default IndexPage;
