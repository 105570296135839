import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import Spacer from "../../layout/Spacer";
import FlexColumn from "../../layout/FlexColumn";


const BusinessPurpose = () => {
  return (
    <Wrapper>
      <div className="images">
        <div className="topImage">
          <StaticImage
            src="../../../images/business_purpose/topImage.webp"
            alt="topImage"
            objectFit="cover"
            layout="fullWidth"
            loading="eager"
            quality={100}
            placeholder='none'
          />
        </div>
        
      </div>
      <div className="sentence">
        <FlexColumn gap="min(40px, 2.778vw)">
          <h1 className="japanese">
            すべての<br />
            がんばる人に、<br />
            幸せを
          </h1>
          <h1 className="english">
            Best wishes for all the Hard Worker
          </h1>
        </FlexColumn>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  //padding-top: min(69px, 4.792%);
  background-color: #204e73;
  text-align: center;
  position: relative;

  .images {
    position: relative;

    .hinomaru {
      width: 50%;
      margin: 0 auto;
    }
  }

  .sentence {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-90%) translateY(-65%);
    font-family: 'Yu Gothic Pr6N';
    font-weight: 700;
    text-align: left;

    .japanese {
      font-size: min(81.7333px, 5.676vw);
      color: #ffffff;
      line-height: 180%;
      letter-spacing: 0.2em;
      white-space: nowrap;
    }

    .english {
      font-size: min(23.46px, 1.629vw);
      color: #f2f2f2;
      line-height: 180%;
      letter-spacing: 0.2em;
    }
  }
`;

export default BusinessPurpose;
