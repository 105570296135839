import styled from "@emotion/styled";

const FlexColumnCenter = styled.div<{
  gap?: string;
}>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: ${(props) => props.gap || '0'};
`;

export default FlexColumnCenter;
