import React, { FC } from "react";
import styled from "@emotion/styled";
import FlexRow from "../../../layout/FlexRow";
import FlexRowCenter from "../../../layout/FlexRowCenter";

interface Props {
  image?: React.ReactNode;
  LogoText?: string;
  title: string;
  children: React.ReactNode;
}

const AboutLogo: FC<Props> = (props) => {
  const { image, LogoText, title, children } = props;

  return (
    <Wrapper>
      <FlexRow className="contents" gap="min(50px, 3.472vw)">
        {image ? (
          <FlexRowCenter className="image">{image}</FlexRowCenter>
        ) : (
          <FlexRowCenter className="text">{LogoText}</FlexRowCenter>
        )}
        <h1 className="title">{title}</h1>
        <p className="paragraph">{children}</p>
      </FlexRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;

  .image {
    width: 4vw;
    max-width: 53px;
    min-width: 30px;
  }

  .text {
    font-size: max(min(60px, 4.167vw), 30px);
    font-family: 'TT Norms Pro';
    font-weight: 700;
    /* line-height: 150%; */
    color: #bdbdbd;
    width: 4.167vw;
    min-width: 30px;
    max-width: 53px;
  }

  .title {
    /* font-family: "Noto Sans", sans-serif;
    font-family: "Noto Sans JP", sans-serif; */
    font-family: 'TT Norms Pro';
    font-weight: 700;
    line-height: 150%;
    font-size: min(24px, 1.667vw);
    color: #333333;
    width: 12.375vw;
    max-width: 135px;
    min-width: 50px;
  }

  .paragraph {
    font-family: 'Yu Gothic Pr6N';
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.03em;
    font-size: min(12px, 0.833vw);
    color: #4f4f4f;
    
    width: 80%;
    max-width: 300px;
    /* white-space: nowrap; */
    /* margin-right: min(37px, 2.569vw); */
  }

  @media screen and (max-width: 768px) {
    .contents {
      justify-content: center;
      //padding-left: 5vw;
      //padding-right: 5vw;
    }
  }
`;

export default AboutLogo;
