import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import FlexColumnCenter from "../../layout/FlexColumnCenter";
import FadeInBottom from "../../common/FadeInBottom";
import Spacer from "../../layout/Spacer";


const Mission = () => {
  return (
    <Wrapper>
      <div className="main">
        
        <StaticImage
          placeholder='none'
          id="mission"
          src="../../../images/mission/grid.webp"
          alt="grid image"
          layout="fullWidth"
          quality={100}
          style={{ maxHeight: "1131px" }}
        />
        
        <FlexColumnCenter className="contents">
          <StaticImage
            placeholder='none'
            src="../../../images/mission/vision.webp"
            alt="vision"
            quality={100}
            style={{ width: "23.611%", maxWidth: "330px" }}
          />

          <div className="sentence">
            <p className="sentence-main">
            懸命に働くすべての人を応援し<br/>
            努力と幸福がより身近になる世界をつくる
            </p>
            <p className="sentence-sub">
            私たちは製造業・建設業の技術伝承を通じて<br/>
            日本の職人のグローバル展開を行います。
            </p>
          </div>
          <FadeInBottom>
            <StaticImage
              placeholder='none'
              src="../../../images/mission/mission_main_image.webp"
              alt="mission image"
              quality={100}
              style={{ transform: "translateX(-2%)"}}
            />
          </FadeInBottom>
        </FlexColumnCenter>
      </div>

      <Spacer height="min(187px, 12.986vw)" />
      

      <div className="bottom">
        <Spacer height="min(100px, 10.556vw)" />
       
      </div>

    </Wrapper>
  );
};

const Wrapper = styled.div`
  .main {
    position: relative;
  }

  .contents {
    width: 100%;
    position: absolute;
    top: 0;
    padding-top: min(209px, 14.514%);

    .sentence {
      color: #4f4f4f;
      font-family: 'Yu Gothic Pr6N';
      font-weight: 700;

      &-main {
        font-size: min(49.3126px, 3.424vw);
        width: 100%;
        
        white-space: nowrap;
        margin: 0 auto;
        margin-top: min(10%, 72px);
        /* transform: translateX(5%); */
        line-height: 160%;
        letter-spacing: 0.03em;
        text-align: center;
      }

      &-sub {
        font-size: min(25.715px, 1.786vw);
        line-height: 180%;
        letter-spacing: 0.03em;
        text-align: center;
        margin: 0 auto;
        margin-top: min(2%, 14.4px);
      }
    }
  }

  .bottom {
    width: 80%;
    margin: 0 auto;

    &-wrapper {
      width: 80%;
      max-width: 653px;
      margin: 0 auto;
    }

    &-sentence {
      font-size: min(23px, 1.597vw);
      color: #4f4f4f;
      line-height: 260%;
      font-weight: 700;
      font-family: 'Yu Gothic Pr6N';
      letter-spacing: 0.03em;
    }
  }
`;

export default Mission;
