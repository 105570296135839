import React, { useState } from 'react'
import styled from '@emotion/styled'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { Link } from 'gatsby'

const HamburgerMenu = () => {

    const [isChecked, setIsChecked] = useState(false);

    const onClickIcon = () => {
        setIsChecked(!isChecked);
    }

    const onClickList = () => {
        setIsChecked(false);
    }


    return (
        <Wrapper>
            <input type="checkbox" className="menu-btn" id="menu-btn" checked={isChecked} onClick={onClickIcon} />
            <label htmlFor="menu-btn" className="menu-icon">
                <span className="navicon"></span>
            </label>
            <ul className="menu">
                <li onClick={onClickList}>
                    <AnchorLink className='link' to={'/#News'}>News</AnchorLink>
                </li>
                <li onClick={onClickList}>
                    <AnchorLink className='link' to={'/#mission'}>vision&Mission</AnchorLink>
                </li>
                <li onClick={onClickList}>
                    <AnchorLink className='link' to={'/#about'}>About</AnchorLink>
                </li>
                <li onClick={onClickList}>
                    <AnchorLink className='link' to={'/#company'}>Company</AnchorLink>
                </li>
                <li onClick={onClickList}>
                    <AnchorLink className='link' to={'/#contact'}>Contact</AnchorLink>
                </li>
                <li onAuxClick={onClickList}>
                    {/* <Link to="/career">*/}
                    <Link to='/https://www.wantedly.com/companies/itips'>Career</Link>
                </li>
            </ul>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    z-index: 100;

    /* Nav items */
    .menu {
        width: 100vw;
        background-color: #204e73;
        list-style: none;
        position: absolute;
        height: auto;
        top: 0;
        right: 0;
        margin-top: 52px;
        padding: 0 0 10px 0;
        clear: both;
        transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
        transform: scale(1, 0);
        transform-origin: top;
        text-align: center;

        a {
            text-decoration: none;
            font-weight: bold;
            letter-spacing: 2px;
            font-size: min(16px, 3.2vw);
            text-transform: capitalize;
            color: #ddd;
            opacity: 0;
            transition: 0.5s;
        }

        li {
            border-bottom: 1px solid #ffffff;
            padding: min(15px, 3%) 0;
            margin: 0 min(54px, 10.8%);
            opacity: 0;
            transition: 0.5s;
        }
    }

    .menu-btn:checked ~ .menu {
        transform: scale(1, 1);
        transform-origin: top;
        transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;

        a, li {
            opacity: 1;
            transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.2s;
        }
    }

    .menu-btn {
        display: none;
    }

    .menu-icon {
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding: 24px 14px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    .navicon {
        background: #ddd;
        display: block;
        height: 3px;
        width: 26px;
        position: relative;
        transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
    }

    .navicon:before,
    .navicon:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        background: #ddd;
        transition: 0.3192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
    }

    .navicon:before {
        top: 9px;
    }

    .navicon:after {
        bottom: 9px;
    }

    /* Hamburger Menu Animation Start */
    .menu-btn:checked ~ .menu-icon .navicon:before {
        transform: rotate(-45deg);
    }

    .menu-btn:checked ~ .menu-icon .navicon:after {
        transform: rotate(45deg);
    }

    .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before {
        top: 0;
    }
    .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
        bottom: 0;
    }

    .menu-btn:checked ~ .menu-icon .navicon {
        background: rgba(0, 0, 0, 0);
        transition: 0.2192s cubic-bezier(0.04, 0.04, 0.12, 0.96) 0.1008s;
    }
`


export default HamburgerMenu