import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import FlexRowCenter from "../../../layout/FlexRowCenter";
import FlexRowTopLeft from "../../../layout/FlexRowTopLeft";
import FlexColumn from "../../../layout/FlexColumn";
import Spacer from "../../../layout/Spacer";
import CircleCaption from "../../../common/CircleCaption";
import FlexColumnCenter from "../../../layout/FlexColumnCenter";
import FadeInBottom from "../../../common/FadeInBottom";
import TitleWithBorderWhite from "../../../common/TitleWithBorderWhite";
import Flex from "../../../layout/Flex";
import FlexRow from "../../../layout/FlexRow";
import Center from "../../../layout/Center";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useNewsData } from "./useNewsData";

// CircleCaptionのCircleサイズ
const LargeCircleSize = "min(427px, 29.653vw)";
const SmallCircleSize = "min(358px, 24.861vw)";

// clients logo animation 用
const delay = 0.03;


//Newsコンテンツはhygraph https://hygraph.com/ にて管理
//アカウントはonepasswordに記載

const News = () => {
  const newsModels = useNewsData();
  // 最新から5件を表示
  const latestNews = newsModels.filter((elm: any, index: number) => index <= 4);

  return (
    <Wrapper>
      <FlexRowCenter>
        <p id="News" className="title">
          News
        </p>
      </FlexRowCenter>

      <ul className="newslist">
        {latestNews.map((news: any) => (
          <li>
            <FlexRowTopLeft>
              <time dateTime={news.node.date}>
                {String(news.node.date).replaceAll("-", ".")}
              </time>
              <a href={news.node.url} target="_blank">
                <p>{news.node.contentText}</p>
              </a>
            </FlexRowTopLeft>
          </li>
        ))}
      </ul>
      <FlexRowCenter>
        <Link to="news">
          <p>さらに表示</p>
        </Link>
      </FlexRowCenter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: min(70px, 10.764%);
  padding-bottom: min(70px, 10.347%);
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  color: #ffffff;
  background-color: #204e73;

  .title {
    font-family: "TT Commons";
    font-size: min(99.94px, 6.94vw);
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 160%;
    color: #f2f2f2;
  }

  .newslist {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
    font-family: "Yu Gothic Pr6N";
    //font-weight: 700;
    letter-spacing: 0.03em;
    line-height: 160%;
    font-size: min(1.5vw, 18px);
  }

  .newslist li {
    margin-bottom: 20px;
  }
  .newslist li a {
    text-decoration: underline;
  }

  .newslist li time {
    padding-right: 20px;
  }
`;

export default News;
