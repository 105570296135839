import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { AnimatePresence } from "framer-motion";

const CareerButton = () => {
  return (
    <AnimatePresence exitBeforeEnter>
      <Link to="https://www.wantedly.com/companies/itips" target="_blank">
        <Button>Career</Button>
      </Link>
    </AnimatePresence>
  );
};

const Button = styled.button`
  width: 10vw;
  max-width: 225px;
  height: 5.972vw;
  max-height: 86px;
  background-color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "TT Norms Pro";
  font-weight: 700;
  font-size: min(24px, 1.667vw);
  line-height: 150%;
  color: #ffffff;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%
    );
    transform: skewX(-25deg);
  }

  &:hover::before {
    animation: shine 0.7s;
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }
`;
export default CareerButton;
