import React from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";

import FlexRowCenter from "../../../layout/FlexRowCenter";
import FlexColumn from "../../../layout/FlexColumn";
import Spacer from "../../../layout/Spacer";
import CircleCaption from "../../../common/CircleCaption";
import DetailButton from "./DetailButton";
import FlexColumnCenter from "../../../layout/FlexColumnCenter";
import FadeInBottom from "../../../common/FadeInBottom";
import TitleWithBorderWhite from "../../../common/TitleWithBorderWhite";
import Flex from "../../../layout/Flex";
import TitleNumber from "./TitleNumber";
import Circle from "./Circle";
import FlexRow from "../../../layout/FlexRow";
import Center from "../../../layout/Center";

// CircleCaptionのCircleサイズ
const LargeCircleSize = "min(427px, 29.653vw)";
const SmallCircleSize = "min(358px, 24.861vw)";

// clients logo animation 用
const delay = 0.03;

const About = () => {
  return (
    <Wrapper>
      <FlexRowCenter>
        <p id="about" className="title">
          About “iTips”
        </p>
      </FlexRowCenter>

      <FlexColumn className="contents" gap="min(15.347vw, 221px)">
        <div className="service">
          <TitleWithBorderWhite title={"SERVICE"} />
          <Spacer height='min(83.25px, 5.781vw)' />
          <FlexColumnCenter gap='min(57px, 3.958vw)'>
            <Flex className='circles-large' gap='min(57px, 3.958vw)'>
              <CircleCaption
                size={LargeCircleSize}
                StaticImage={
                  <StaticImage
                    placeholder="none"
                    src="../../../../images/about/about_service_construction.webp"
                    alt="construction image"
                    style={{
                      width: LargeCircleSize,
                      height: LargeCircleSize,
                      borderRadius: "50%",
                      opacity: "0.65",
                    }}
                    quality={100}
                  />
                }
              >
                <p>
                  モノづくり
                </p>
              </CircleCaption>

              <CircleCaption
                size={LargeCircleSize}
                StaticImage={
                  <StaticImage
                    placeholder="none"
                    src="../../../../images/about/about_service_education.webp"
                    alt="education image"
                    style={{
                      width: LargeCircleSize,
                      height: LargeCircleSize,
                      borderRadius: "50%",
                      opacity: "0.65",
                    }}
                    quality={100}
                  />
                }
              >
                <p>ヒトづくり</p>
              </CircleCaption>
            </Flex>

            

          </FlexColumnCenter>

          <Spacer height="min(96px, 6.667vw)" />
          <Center>
            <DetailButton />
          </Center>
        </div>


{/*
        <div className="result">
          <FlexColumnCenter>
            <TitleWithBorderWhite title="RESULT" />
            <Spacer height="min(115px, 8vw)" />
            <FadeInBottom>
              <FlexRowCenter className="result-main" gap="min(72px, 2vw)">
                <FlexColumn className="result-comp">
                  <Flex className="total-construction" gap="min(22px, 1.528vw)">
                    <p className="total-construction-text">
                      インドでの
                      <br />
                      総施工数
                    </p>
                    <FadeInBottom>
                      <p className="total-construction-number">220+</p>
                    </FadeInBottom>
                  </Flex>
                  <Spacer height="min(0.269vw, 3.88px)" />
                  <FlexRow>
                    <FadeInBottom>
                      <TitleNumber title={"進出"} number={"1996"} unit={"年"} />
                    </FadeInBottom>
                    <div className="result-comp-border"></div>
                    <FadeInBottom>
                      <TitleNumber
                        title={"スタッフ"}
                        number={"30"}
                        unit={"名以上"}
                      />
                    </FadeInBottom>
                    <div className="result-comp-border"></div>
                    <FadeInBottom>
                      <TitleNumber
                        title={"取引先"}
                        number={"40"}
                        unit={"社以上"}
                      />
                    </FadeInBottom>
                  </FlexRow>
                </FlexColumn>

                <p className="top-right">
                20世紀からインドの製造業と建設業の発展に寄与してきた最古の日系企業の<br/>
                系譜を継ぎ、工場や駅舎、スタジアムなどの様々な建設プロジェクトとの<br/>
                ご縁を頂き、建材メーカーとして設計・製造業務、サブコンと<br/>
                して設計・施工管理業務に携わっています。
                </p>
              </FlexRowCenter>
            </FadeInBottom>
            <Spacer height={"min(5.972vw, 86.32px)"} />
            <div
              className="indian-wrapper"
              style={{ width: "80%", maxWidth: "1440px" }}
            >
              <StaticImage
                placeholder="none"
                src="../../../../images/about/map.webp"
                alt="inidian map"
                quality={100}
                layout="fullWidth"
                style={{ maxWidth: "1440px", margin: "0 auto" }}
              />
             
            </div>
            <Spacer height="min(8vw, 100px)" />

            <FlexColumn className="clients">
              <p>クライアント:</p>
              <Spacer height="3.333vw" />

              <FlexRow>
                <FadeInBottom className="logo">
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/toyota.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>

                <FadeInBottom className="logo" delay={delay}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/ykkap.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>

                <FadeInBottom className="logo" delay={delay * 3}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/takenaka.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>


                <FadeInBottom className="logo" delay={delay * 2}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/shapoorjipallonji.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>

              </FlexRow>

              

              <FlexRow>
                <FadeInBottom className="logo" delay={delay * 5}>
                    <StaticImage
                      placeholder="none"
                      className="clients-image"
                      src="../../../../images/companies_logo/kajima.png"
                      alt="clients"
                      quality={100}
                      layout="fullWidth"
                    />
                  </FadeInBottom>
                <FadeInBottom className="logo" delay={delay * 5}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/suzuki.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>
                <FadeInBottom className="logo" delay={delay * 6}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/mwgroup.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>
                <FadeInBottom className="logo" delay={delay * 7}>
                  <StaticImage
                    placeholder="none"
                    className="clients-image"
                    src="../../../../images/companies_logo/nmdc.png"
                    alt="clients"
                    quality={100}
                    layout="fullWidth"
                  />
                </FadeInBottom>
               
              </FlexRow>
            </FlexColumn>
          </FlexColumnCenter>
        </div>

        */}

        <Spacer height={"min(4vw, 40px)"} />

      </FlexColumn>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-top: min(155px, 10.764%);
  font-family: "Noto Sans", sans-serif;
  font-family: "Noto Sans JP", sans-serif;
  color: #ffffff;
  background-color: #204e73;

  .title {
    font-family: "TT Commons";
    font-size: min(99.94px, 6.94vw);
    letter-spacing: 0.03em;
    font-weight: 700;
    line-height: 160%;
    color: #f2f2f2;
  }

  .contents {
    padding-top: min(149px, 10.347%);
    font-family: "Noto Sans", sans-serif;
    font-family: "Noto Sans JP", sans-serif;
    color: #ffffff;
    background-color: #204e73;

    .service {
      .circles-large {
        p {
          font-size: min(36px, 2.5vw);
          line-height: 160%;
        }
      }

      .circles-small {
        p {
          font-size: min(25px, 1.736vw);
          line-height: 160%;
        }
      }
    }

    .result {
      /* width: 80%; */
      width: 100%;
      margin: 0 auto;

      .result-main {
        align-items: flex-start;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
      }

      .result-comp {

        .total-construction {
          @media screen and (max-width: 768px) {
            justify-content: space-between;
          }

          .total-construction-text {
            font-size: min(1.528vw, 22px);
            padding-top: 2%;
            font-family: "Yu Gothic Pr6N";
            font-weight: 700;
            line-height: 130%;
            letter-spacing: 0.03em;
            white-space: nowrap;

            @media screen and (max-width: 768px) {
              font-size: min(3vw, 22px);
            }
          }

          .total-construction-number {
            font-size: min(11.035vw, 158.904px);
            font-family: "TT Commons";
            font-weight: 500;
            line-height: 100%;
            letter-spacing: 0.03em;
          }
        }

        .result-comp-border {
          width: 0.5%;
          max-width: 3px;
          height: 5.841vw;
          max-height: 84.11px;
          background-color: #ffffff;
          opacity: 0.5;
        }
      }

      .top-right {
        text-align: left;
        font-family: "Yu Gothic Pr6N";
        font-weight: 500;
        font-size: min(1.5vw, 16px);
        margin-top: 1%;
        line-height: 230%;
        letter-spacing: 0.03em;
        white-space: nowrap;

        @media screen and (max-width: 768px) {
          margin: 0 auto;
          margin-top: 10px;
          width: 100%;
          white-space: pre-wrap;
        }
      }

      .indian-wrapper {
        position: relative;

        .circle-1 {
          position: absolute;
          top: 21.7%;
          left: 28.2%;
        }

        .circle-2 {
          position: absolute;
          top: 40%;
          left: 54.2%;
        }

        .circle-3 {
          position: absolute;
          top: 60.5%;
          left: 35.8%;
        }
      }

      .clients {
        width: 80%;
        max-width: 1440px;
        p {
          font-family: "Yu Gothic Pr6N";
          font-weight: 700;
          line-height: 150%;
          font-size: min(1.25vw, 16px);
        }
        .clients-image {
          transition: 0.3s;
          filter: opacity(0.5);

          :hover {
            filter: opacity(1);
          }
        }

        .logo {
          width: 14%;
        }
      }
    }

    
  }
`;

export default About;
