import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import CareerButton from "./CareerButton";
import Navigation from "./Navigation";
import HamburgerMenu from "./HamburgerMenu";
import { LangChangeButton } from "./LangChangeButton";

const Header = () => {
  return (
    <Wrapper id="top">
      <div className="logo">
        <Link to="/">
          <StaticImage
            placeholder="none"
            src="../../../images/header/HeaderLogo.svg"
            alt="iTips inc."
            layout="fullWidth"
            quality={100}
          />
        </Link>
      </div>
      <div className="navigation">
        <Navigation />
      </div>
      <div className="career">
        <LangChangeButton />
        <CareerButton />
      </div>
      <div className="hamburger">
        <HamburgerMenu />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #204e73;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .logo {
    margin-left: min(39px, 2.708%);
    margin-bottom: min(10px, 0.694%);
    width: 17.639%;
    max-width: 254px;

    @media screen and (max-width: 768px) {
      width: 25%;
      max-width: 150px;
      min-width: 100px;
    }
  }

  .navigation {
    margin-right: min(50px, 3.472%);
    margin-left: min(61px, 12%);

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .career {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .hamburger {
    display: none;
    margin-right: 0;
    margin-left: auto;
    z-index: 10;

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
`;

export default Header;
