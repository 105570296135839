import styled from "@emotion/styled";

const Flex = styled.div<{
  gap?: string;
}>`
  display: flex;
  gap: ${(props) => props.gap || '0'};
`;

export default Flex;
