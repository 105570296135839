import React from "react";
import styled from "@emotion/styled";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import FlexRowCenter from "../../layout/FlexRowCenter";

const Navigation = () => {
  return (
    <Wrapper>
      <FlexRowCenter gap="min(62px, 4.306vw)">
      <AnchorLink className="link" to={"#News"}>
          News
        </AnchorLink>
        <AnchorLink className="link" to={"#mission"}>
          Mission
        </AnchorLink>
        <AnchorLink className="link" to={"#about"}>
          About
        </AnchorLink>
        <AnchorLink className="link" to={"#company"}>
          Company
        </AnchorLink>
        <AnchorLink className="link" to={"#contact"}>
          Contact
        </AnchorLink>
      </FlexRowCenter>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: #f2f2f2;
  font-family: 'TT Norms Pro';
  font-weight: 700;
  font-size: min(24px, 1.667vw);
  line-height: 150%;

  .link {
    position: relative;
    white-space: nowrap;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 10%;
      width: 80%;
      height: 2px;
      background-color: #f2f2f2;
      transition: all 0.3s;
      transform: scale(0, 1);
      transform-origin: center top;
    }

    &:hover::after {
      transform: scale(1, 1);
    }
  }
`;

export default Navigation;
